
@use '../../media/Root.scss';

svg{
    height: auto;

    .hover-animation{
        stroke-dasharray: unset;
        stroke-dashoffset: 0;
    }

    &:hover {
        .hover-animation{
            stroke-dasharray: 500;
            stroke-dashoffset: 1000;
        }   
    }
}

.fill-path {
    stroke-dasharray: 500;
    stroke-dashoffset: 1000;
    animation: dash 3s linear infinite;
  }

  .fill{
    offset: 0;
    stop-color: "#4DAF4C";
    fill: "freeze";
    animation: filloff 2s linear infinite;
  }


@keyframes dash {
    to {
    stroke-dashoffset: 0;
    }
}

@keyframes filloff {
    to {
        offset: "100%";
    }
}