
@use '../../media/Root.scss';

.About {
    min-height: 100vh;

    .ab-nav{
        @media #{Root.$iphone-portrait} {
            display: none;
        }
    }

    .wrapper {
        display: flex;
        justify-content: center;
        max-width: 1280px;
        margin: auto;

        @media #{Root.$iphone-portrait} {
            padding: 5vw;
        }

        .abt-main{
            width: 100%;
            max-width: 90em;
            display: grid;


            .ab-info-wrap{
                display: flex;
                gap: 5vw;

              

                .ab-l{
                    flex:1 1 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 1vw;
                    min-height: 20vw;
                    align-items: flex-start;
                    
                    .exp{
                        text-align: left;
                        display: grid;
                        gap: .5vw;
                        margin-bottom: 1vw;
                        
                        .cap{
                            font-size: 2vw;
                            align-self: flex-start;
                            color: var(--c-theme);
                        }
                        .info{
                            margin-bottom: .5rem;
                            text-align: justify;
                        }
                        .skill{
                            font-weight: 700;
                        }
                    }
                }
                
                .ab-r{
                    flex: 1 1 50%;
                    display: grid;

                    .headshot {

                        img{
                            width: 20rem;
                            border-radius: 5px;
                            box-shadow: 8px 7px 20px 8px var(--color-border);
                        }
                    }

                    a{
                        transition: all 1s;

                        img{
                            width: 2.5rem;
                        }

                        :hover{
                            transform: scale(1.2);
                        }
                    }
                    .social{
                        display: flex;
                        justify-content: center;
                        gap:2rem;
                        height: fit-content;
                    }
                }

                @media #{Root.$iphone-portrait} {
                    flex-direction: column-reverse;

                    .ab-l{
                        justify-content: center;
                        align-items: center;

                        .headshot{
                
                            img{
                                width: 30vw;
                            }
                        }
                    }

                    .ab-r {

                        .exp{
                            .cap{
                                font-size: 6vw;
                            }
                            .info{
                                font-size: 3.5vw;
                            }
                        }
                    }
                }
            }

            .down-wrap {
                transition: .5s;
                width: fit-content;
                justify-self: center;
                animation: blink 2.5s linear infinite;
                
                img{
                    width: 2rem;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }

            @keyframes blink {
                0%,40%,100%{
                    filter: brightness(.8);
                }
                50%{
                    filter: brightness(1.5);
                }
                60%{
                    filter: brightness(.8);
                }
                
            }
        }
    }
    
}

@keyframes drop {
    0%{
        bottom: 0;
    }50%{
        bottom: -95%;
    }55%{
        bottom: -85%;
    }60%{
        bottom: -90%;
        transform: translateX(20px);
    }65%{
        bottom: -95%;
        transform: translateX(30px);
    }100%{
        bottom: -95%;
        transform: translateX(30px);
    }

}