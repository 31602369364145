@use '../../media/Root.scss'; 

.Home{
    min-height: 100vh;
    align-items: center;

    a{
        cursor: pointer;
    }

    .nav-home {
      overflow: hidden;
      width: 100%;
      position: fixed;
      z-index: 10;
      transition: .5s;
      height: 0;
      align-items: center;
      border-bottom: 2px solid var(--color-border);

      svg {
        display: none;
        transition: .5s;
      }
    }
  

    @media #{Root.$iphone-portrait} {
      .nav-home{
        display: none;
      }

      // .nav-home{
      //   display: flex;
      //   flex-direction: column;
      //   align-self: flex-end;
      //   width: 12vh;
      //   right: 0;
      //   height: 100%;

      //   .nav-btn {
          
      //     svg{
      //       display: inline;
      //       width: 2rem;
      //       margin: auto;
      //     }

      //     .menu{
      //       opacity: 0;
      //       animation: fadeIn 1s linear forwards;
      //     }

      //     .cross{
      //       opacity: 0;
      //       animation: fadeIn 1s linear forwards;
      //       display: none;
      //     }

      //     @keyframes fadeIn {
      //       to{
      //         opacity: 1;
      //       }
      //     }
      //   }


      //   .nav-wrap{
      //     top: 70vh;
      //     height: fit-content;
      //     position: inherit;
      //     transition: .5s;
      //     display: grid;
      //     gap: .5rem;
      //   }
      // }
      
      // .nav-btn{
      //   transition: .5s;
      //   background: rgb(128 128 128);
      //   border-radius: 5px;
      //   height: 2.5rem;
      //   display: flex;
      // }
    }

    .toggle-menu{

      .nav-wrap{
        top: 20vh;
      }

      nav{
        opacity: 1;
        height: 50vh;
      }

      .nav-btn{


        .menu{
          display: none;
        }

        .cross{
          display: block;
        }

      }
    }

    .wrapper{
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1vw 0;
            opacity: 0;
            animation: reveal .5s linear 1.5s forwards;
            z-index: 10;


            svg{
                width: 3.5rem;
            }

            .drop-root{
                transition: 0.5s;
                border-radius: 5px;

                .drop-menu{
                    transition: .5s;
                }
            }

            @media #{Root.$ipad-pro-portrait} {
              padding: 2vw 0;
              svg{
                width: 6.5vw;
            }
          }

            @media #{Root.$iphone-portrait} {
              padding: 4vw 0;
              svg{
                width: 12.5vw;
            }
          }

        }


        .main{
              display: flex;
              flex-direction: column;
              gap: 1vw;
              align-items: center;
            
            
              .hex-container{
                position: absolute;
                right: 1rem;
                top: 10vw;
                filter: brightness(.5);
                opacity: 0;
                animation: reveal .3s linear 1.5s forwards, flicker 8s linear infinite;
                transition: .5s;

              img{
                width: 25rem;

                @media #{Root.$iphone-portrait} {
                  width: 10rem;
                }
              }
            }

            

            .bottom{
              bottom: 10vw;
              left: 1rem;
              right: unset;
              top: unset;
              animation: reveal .3s linear 1.5s forwards, flicker 8s linear 3s infinite;
            }


            .hide-overflow{
              overflow: hidden;
            }

            .intro-wrap{
                transform: translateY(100%);
                animation: slideUp .7s linear .5s forwards;

                .h-l1{
                  font-size: 4.065vw;
                  
                  span{
                      font-weight: 700;
                  }
              }

              .h-l2{
                  font-size: 3vw;
                  font-weight: 700;
                  color: var(--c-theme);
              }
            }
            
            
            .hide-overflow-btn {
              overflow: hidden;
              transition: .5s;
            
              .h-l3 {
                  font-size: clamp(1.2rem,1.5vw,1.5rem);
                  // width: fit-content;
                  border: 2px solid var(--color-border);
                  padding: 10px;
                  border-radius: 10px;
                  display: grid;
                  grid-template-columns: 5fr 1fr;
                  align-items: center;
                  transition: .4s;
                  gap: 1vw;
                  transform: translateY(-100%);
                  animation: slideDown .2s linear 1.5s forwards;

                img{
                    width: 1.5rem;
                    transition: .4s;
                }
            }

            &:hover{
              scale: 1.1;
              

              .h-l3{
                border-color: var(--c-theme);
              }

              img{
                  transform: rotate(90deg);
              }
          }

          }
            

            @media #{Root.$ipad-pro-portrait}, #{Root.$iphone-portrait} {
              .hex-container{
                top: 40vw;
              }
              .bottom{
                bottom: 40vw;
                left: 0;
                right: unset;
                top: unset;
              }
            }

            @media #{Root.$iphone-portrait} {
              gap: 4vw;

              .intro-wrap .h-l1{
                font-size: 7vw;
              }
              .intro-wrap .h-l2{
                font-size: 4vw;
              }
              
              .hide-overflow-btn{
                .h-l3{
                  font-size: 4.5vw;

                  img{
                    width: 4vw;
                  }
                }
              }
            }
          }

            .foot {
                margin-bottom: 4vw;
                opacity: 0;
                transform: translateY(100%);
                animation: popScroller .2s ease-out 1.8s forwards;
                min-height: 4vw;

                .scroller-wrapper{
                    height: 4vw;
                    width: 2vw;
                    border: 5px solid var(--c-theme);
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-height: 4rem;
                    max-width: 2rem;
                    min-height: 4rem;
                    min-width: 2rem;


                    .scroller {
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: var(--c-theme);
                        animation: updown 2s linear infinite;
                    }


                    @keyframes updown {
                        from {
                            transform: translateY(-15px);
                            opacity: 0;
                        }
                        50%{
                          opacity: 1;
                        }
                        to{
                            transform: translateY(15px);
                            opacity: 0;
                        }
                    }

                }

                @media #{Root.$iphone-portrait} {
                  margin-bottom: 25vw;
                }
            }
        }

    }

    .Dropdown-root {
        position: relative;
      }
      
      .Dropdown-control {
        position: relative;
        overflow: hidden;
        border: 1px solid var(--color-border);
        border-radius: 5px;
        box-sizing: border-box;
        cursor: default;
        outline: none;
        padding: 4px 25px 4px 10px;
        transition: all .5ms ease;
        font-size: 1rem;

        @media #{Root.$iphone-landscape} {
          padding: 4px 22px 4px 12px;
        }
      }
      
      .Dropdown-control:hover {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      }
      
      .Dropdown-arrow {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: " ";
        display: block;
        height: 0;
        margin-top: -ceil(2.5);
        position: absolute;
        right: 10px;
        top: 12px;
        width: 0;

        @media #{Root.$iphone-landscape} {
          right: 6px;
        }
      }
      
      .is-open .Dropdown-arrow {
        border-color: transparent transparent #999;
        border-width: 0 5px 5px;
        border-radius: 5px 5px 0 0;
      }
      
      .Dropdown-menu {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
        border: 1px solid var(--color-border);
        border-radius: 0 0 5px 5px;
        font-size: 1rem;
      }
      
      .Dropdown-menu .Dropdown-group > .Dropdown-title{
        padding: 4px 10px;
        font-weight: bold;
        text-transform: capitalize;
      }
      
      .Dropdown-option {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 4px 10px;

        @media #{Root.$iphone-landscape} {
          padding: 4px 8px;
        }
      }
      
      .Dropdown-option:last-child {
        border-bottom-right-radius: 2px;
         border-bottom-left-radius: 2px;
      }
      
      .Dropdown-option:hover {
        background-color: #f2f9fc;
      }
      
      .Dropdown-option.is-selected {
        border: 1px solid var(--c-theme);
      }
      
      .Dropdown-noresults {
        box-sizing: border-box;
        color: #ccc;
        cursor: default;
        display: block;
        padding: 8px 10px;
      }

      
      @keyframes slideUp {
        0%{
          transform: translateY(100%);
        }
        20%,80%{
          transform: translateY(40%);
        }
        100%{
          transform: translateY(0);
        }
      }

      @keyframes slideDown {
        100%{
          transform: translateY(0);
        }
      }

      @keyframes reveal {
        100%{
          opacity: 1;
        }
      }

      @keyframes popScroller {
        to{
          transform: translateY(0);
          opacity: 1;
        }
      }


      @keyframes flicker {
        20%, 100% {
          filter: brightness(.5);
          transform: scale(1);
        }
        0%{
          filter: brightness(1.5);
          transform: scale(1.02);
        }
        10%{
          filter: brightness(1.5);
        }
        11%{
          filter: brightness(0.5);
        }
        12%{
          filter: brightness(1.5);
        }
        13%{
          filter: brightness(0.5);
        }
        14%{
          filter: brightness(1.5);
        }
        15%{
          filter: brightness(0.5);
        }
        16%{
          filter: brightness(1.5);
        }
        17%{
          filter: brightness(0.5);
        }
        18%{
          filter: brightness(1.5);
        }
        19%{
          filter: brightness(0.5);
          transform: scale(1.02);
        }
        20%{
          filter: brightness(.5);
          transform: scale(1);
        }
      }