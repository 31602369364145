@use './media/Root.scss';


:root {
  --c-white: #ffffff;
  --c-white-soft: #f8f8f8;
  --c-white-mute: #f2f2f2;

  --c-black: #181818;
  --c-black-soft: #222222;
  --c-black-mute: #282828;

  --c-indigo: #2c3e50;

  --c-divider-light-1: rgba(60, 60, 60, 0.29);
  --c-divider-light-2: rgba(60, 60, 60, 0.12);
  --c-divider-dark-1: rgba(84, 84, 84, 0.65);
  --c-divider-dark-2: rgba(84, 84, 84, 0.48);

  --c-text-light-1: var(--c-indigo);
  --c-text-light-2: rgba(60, 60, 60, 0.66);
  --c-text-dark-1: var(--c-white);
  --c-text-dark-2: rgba(235, 235, 235, 0.64);

  --size-font-large1: clamp(2rem,4.5vw,5rem);
  --size-font-large2: clamp(1.5rem,4.5vw,4rem);
  --size-font-button: clamp(1.2rem, 1.5vw, 2rem);
  --size-font-content: clamp(1.2rem,1.5vw,1.5rem);
  --size-font-label: clamp(3rem,3.5vw,4rem);
}

/* semantic color variables for this project */
:root {
  --color-background: var(--c-white);
  --color-background-soft: var(--c-white-soft);
  --color-background-mute: var(--c-white-mute);

  --color-border: var(--c-divider-light-2);
  --color-border-hover: var(--c-divider-light-1);

  --color-heading: var(--c-text-light-1);
  --color-text: var(--c-text-light-1);

  --section-gap: 160px;

  --c-theme:#03C4A1;

  --font-intro: var(--size-font-large1);
  --font-role: var(--size-font-large2);
  --font-link:var(--size-font-button);
  --font-content:var(--size-font-content);
  --font-label:var(--size-font-label);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--c-black);
    --color-background-soft: var(--c-black-soft);
    --color-background-mute: var(--c-black-mute);

    --color-border: var(--c-divider-dark-2);
    --color-border-hover: var(--c-divider-dark-1);

    --color-heading: var(--c-text-dark-1);
    --color-text: var(--c-text-dark-2);
  }
}

body {
  height: 100vh;
  color: var(--color-text);
  background: var(--color-background);
  transition: color 0.5s, background-color 0.5s;
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  text-align: center;
  //color: var(--c-text-light-1);
  font-family: 'Manrope', sans-serif;
  font-size:var(--font-content);
}

.bg-app{
  background: transparent;
}

.padding {
  padding: 0 5vw;
}

a {
    cursor: pointer;
}

.label {
  font-size: var(--font-label);
  border-bottom: 5px solid var(--color-border);
  padding: 0 1rem;
  align-self: center;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.loader-container{
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;

  svg{
    width: 10vw;
  }
}
