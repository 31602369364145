@use '../../media/Root.scss';


.Contact{
    padding: 5vw;
    
    .c-cont{
        display: grid;

        .social{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 2rem;
            gap: 0.5rem;

            .s-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1vw;
                font-size: 1rem;
                font-weight: 500;

                img{
                    width: 2.5rem;
                }
            }

            @media #{Root.$iphone-portrait} {
                display: none;
            }
        }

        .form-wrap{
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 60rem;
            min-height: 20vw;
            align-items: center;
            margin: 0 auto;
            border-radius: 5px;
            overflow: hidden;
            transition: .5s;
            border: 1px solid var(--color-border);

            &:hover{
                border: 1px solid var(--color-border-hover);
            }

            .left{
                padding: 2vw;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                form{
                    display: flex;
                    flex-direction: column;
                    gap: .8rem;
                    

                    input[type="text"],input[type="email"]{
                        font-size: .8rem;
                        border-radius: 5px;
                        background-color: var(--color-background-soft);
                        border: none;
                        padding: 10px;
                        color: var(--color-text);

                        &:focus-visible{
                            outline: none;
                        }
                    }

                    textarea{
                        height: 10rem;
                        min-height: 150px;
                        font-size: .8rem;
                        resize: none;
                        border-radius: 5px;
                        background-color: var(--color-background-soft);
                        border: none;
                        padding: 10px;
                        color: var(--color-text);

                        &:focus-visible{
                            outline: none;
                        }
                    }

                    input[type="submit"]{
                        font-size: 1rem;
                        align-self: flex-end;
                        border-radius: 5px;
                        border: none;
                        // border: 1px solid #{Root.$dark-text};
                        background: var(--color-background-soft);
                        transition: .5s;
                        cursor: pointer;
                        color: var(--color-text);
                        padding: 5px 30px;

                        &:hover{
                            transform: scale(1.1);
                            background-color: var(--color-background-mute);
                            border: none;

                            @media #{Root.$iphone-portrait}, #{Root.$ipad-pro-portrait} {
                                transform: none;
                                animation: press .05s linear;
                            }
                        }
                    }
                }
            }

            .right{
                display: flex;
                img{
                    width: 100%;
                    transition: .5s;
                }

            }


            @media #{Root.$iphone-portrait}, #{Root.$ipad-pro-portrait} {
                width: 100%;
                grid-template-columns: 1fr;
                .right{
                    display: none;
                }
                form{

                    input{
                        font-size: 1vw;
                    }
                }
            }
        }
    }
}

@keyframes press {
    to {
        transform: scale(.9);
    }
}

#loader{
    width: 2rem;
    animation: rotate 2s linear infinite;
    justify-self: center;
    margin: 1rem;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.collapse {
    width: 0 !important;
}

