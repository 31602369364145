@use '../../media/Root.scss';

.Work{
    padding: 5vw;

    .w-cont{
        display: flex;
        flex-direction: column;
        align-items: center;

        .fixed-wrapper{
            max-width: 1280px;
            overflow: hidden;
            height: fit-content;
            padding: 10px;
        }

        .work-section{
            gap: 2rem;
            display: flex;
            transition: .5s;
            // transform: translateX(-75%);


            .company{
                font-size: clamp(1em,2vw,1.5em);
                font-weight: 600;
                color: var(--c-theme);
            }

            .title{
                font-size: clamp(.8em,1.5vw,1.2em);
                font-weight: 600;
            }
        }

        .w-sub-cont{
            display: flex;
            justify-content: space-around;
            
            flex-direction: column;
            row-gap: 2em;
            align-items: center;

                .scale{

                    &:hover{
                        transform: scale(1.1);
                    }
                }

                .work-wrapper{
                    display: flex;
                    flex-direction: column;
                    background: var(--color-background);
                    border-radius: 10px;
                    max-width: 1280px;
                    align-items: flex-start;
                    padding: 10px;
                    row-gap: 0.5em;
                    box-shadow: 2px 2px 2px var(--color-border);
                    border: 0.5px solid var(--color-border);
                    cursor: pointer;
                    transition: .5s;

                        &:active{
                            transform: translate(1px,1px);
                            box-shadow: none;
                        }

                        &:hover{
                            background-color: var(--color-background-soft);
                        }

                   

                    .responsibility{
                        text-align: left;
                    }

                    .skills-used{
                        display: flex;
                        gap: .5rem;
                        flex-direction: row;
                        flex-wrap: wrap;

                        span{
                            width: fit-content;
                            font-size: 1rem;
                            background-color: var(--c-theme);
                            font-weight: 600;
                            border-radius: 5px;
                            // color: white;
                            padding: 5px 10px;
                        }
                    }
                }

                .project-container {
                    display: flex;
                    justify-content: space-between;

                    .w-wrapper{
                        display: grid;
                        transition: .5s;
                        border-radius: 5px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        overflow: hidden;
                        min-height: 6rem;
                        min-width: 10rem;
                        max-width: 50%;
                        width: fit-content;
                        
                        .project-info{
                            text-align: left;
                            padding: 10px;
                        }

                        .visit-btn{
                            text-decoration: none;
                            color: #{Root.$dark-text};
                            font-size: 2vw;
                            display: flex;
                            gap: .5vw;
                
                            .link-logo,.link-arrow{
                                width: 3vw;
                            }

                            @media #{Root.$iphone-portrait} {
                                font-size: 6vw;

                                .link-logo, .link-arrow{
                                    width: 6vw;
                                }
                            }
                           
                        }
                
                        .overlay{
                            // height: 100%;
                            
                            width: 40rem;
                            height: auto;
                            // background-color: rgba(255, 255, 255, 0.471);
                            // background: url('../../assets/img/odi.png') no-repeat;
                            background-size: cover;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            transition: 1s;
                
                            .btn-wrap{
                                background-color: #bea7a778;
                                height: 0;
                                width: 100%;
                                align-self: flex-end;
                                align-items: center;
                                display: grid;
                                justify-content: center;
                                overflow: hidden;
                                transition: 1s;
                
                                .visit-btn{
                                    border-radius: 5px;
                                    width: 5vw;
                                    background: red;
                                    text-decoration: none;
                                    color: #FFF5E0;
                                    padding: 2px;
                                    transition: 0.5s;
                                    font-size: 1vw;
                
                                    &:hover{
                                        transform: scale(1.2);
                                    }
                                }
                            }
                        }
                
                        .slide-over{
                            background: #{Root.$dark-theme-fade};
                            position: relative;
                            width: 100%;
                            height: 100%;
                            transform: translateY(-100%);
                            transition: 1s;
                        }
                
                        &:hover{
                            z-index: 1;
                
                            .slide-over{
                                transform: translate(100%,-100%);
                            }
                
                            .btn-wrap{
                                height: 3vw;
                            }
                        }
                    }

                    @media #{Root.$iphone-portrait} {
                            flex-direction: column-reverse;

                            .w-wrapper{
                                max-width: 100%;
                                align-self: center;

                                .overlay{
                                    width: 100%;
                                }
                            }
                    }
                }
        }

        .work-info{
            width: 50em;
            display: flex;
            flex-direction: column;
            text-align: start;

            span{
                width: 50em;
            }
        }
    }
}

.d-flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}



.odd{

    flex-direction: row-reverse;

    .visit-btn{
        flex-direction: row-reverse;
    }
    .link-arrow{
        transform: rotateY(180deg);
    }

    .w-wrapper {

        .project-info{
            text-align: right !important;
        }

        &:hover{
            .slide-over{
                transform: translate(-100%,-100%) !important;
            }
        }
    }
}

@keyframes popup {
    // 80%{
    //     transform: translateY(-10%);
    //     opacity: .9;
    // }
    100%{
        transform: translateY(0) scale(1);
        opacity: 1;
        
    }
}
 
.bg-overlay{
    width: 100vw;
    height: 100vh;
    background: #b6b4b442;
    justify-content: center;
    display: flex;
    position: fixed;
    transition: 1s;
    z-index: 10;
    top: 0;
    left: 0;
    //display: none;
    //opacity: 0;


.exp-info-cont{
    max-width: 1280px;
    margin: auto;
    background: var(--color-background);
    border-radius: 5px;
    transition: .5s;
    position: relative;
    transform: translateY(100%) scale(.8);
    animation: popup .3s ease-out forwards;
    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }

    @media #{Root.$iphone-portrait} {
        margin: 1rem;
    }

    .nav{
        border-bottom: 1px solid var(--color-border);
        text-align: right;
        
       // padding: 1rem 0;
        
        img{
            width: 2rem;
            margin: 1rem;
            cursor: pointer;
        }
    }

    .wrapper{
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        padding:1rem;
        

        .l1{
            flex-basis: 100%;
            font-size: clamp(2rem, 3.5vw, 3rem);
            font-weight: 700;
            
        }

        .l2{
            flex: 1 0 50%;
            font-size: clamp(1.5rem, 2.5vw, 2rem);
        }

        .l3{
            flex: 1 0 50%;
            text-align: right;
            font-size: clamp(1.5rem, 2.5vw, 2rem);
        }

        .content{
            flex:1 1 100%;
            padding: 15px;

            label{
                font-size: 1.5rem;
                font-weight: 700;
            }
            ul{
                font-size: 1.2rem;
            }
        }

        @media #{Root.$iphone-portrait} {
            .l2,.l3{
                flex: 1 1 100%;
                text-align: left;
            }
        }

    }
    

}
}