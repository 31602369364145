@use '../../media/Root.scss';


.Footer {

    .f-container::before{
            content: "";
            width: clamp(20rem, 50vw, 55rem);
            height: 2px;
            position: relative;
            display: block;
            background: var(--color-border);
            margin: 2rem auto;
        }

    .f-container{

       
        .curve{
            height: 10vw;
            background: url('./foot.svg');
            background-size: cover;
            width: 100%;
            display: flex;
            justify-content: center;

            .topArrow{
                display: inherit;
                width: fit-content;
                height: fit-content;

                img{
                    margin-top: 2rem;
                    width: 1.5rem;
                    animation: swingTopBottom 2s linear infinite;
                }
            }

            @keyframes swingTopBottom {
                0%{
                    transform: translateY(50%);
                    opacity: .5;
                } 50% {
                    transform: translateY(0);
                    opacity: 1;
                }
                100% {
                    transform: translateY(-50%);
                    opacity: 0;
                }
            }
        }

        .foot-wrap{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;

            .logo {

                img{
                    width: 4rem;
                }
            }

            .social {
                display: flex;
                gap:  2vw;

                img{
                    width: 2.5rem;
                }

                a {
                    transition: .5s;

                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }

            @media #{Root.$iphone-portrait} {
                height: 15rem;
                padding: 5vw 0;
                justify-content: flex-end;
            }
        }
    }
}