@use '../../media/Root.scss';

nav{
    padding: 0 5vw;
    display: flex;
    gap: 3vw;
    height: 5vw;
    max-height: 5rem;
    background: var(--color-background);
    font-size: 1vw;
    align-items: center;
    font-weight: 700;
    transition: 0.5s;
    border-bottom: 2px solid var(--color-border);
    //backdrop-filter: blur(10px);

    img{
        width: 2rem;
    }

    a{
        padding: .5vw .8vw;
        transition: .3s;

        &:hover{
            transform: scale(1.1);
            color: var(--c-theme);
        }
    }

    @media #{Root.$iphone-portrait} {
        
        // height: 100%;
        // width: 100%;
        overflow: hidden;
        flex-direction: column;
        // font-size: 2rem;
        // padding-top: 4rem;
        // gap: 2rem;
        // opacity: 0;
        padding: 0;

        width: 5rem;
        height: 0;
        border-radius: 5px;
        justify-content: center;
        background: grey;
        max-height: fit-content;

        a{
            margin: 1rem;
        }
    }
}

.active {
    border-bottom: 5px solid var(--c-theme);
    color: var(--c-theme);
}

