
.Skills{
    padding: 5vw;
    min-height: 100vh;

        .s-cont{
            display: flex;
            flex-direction: column;
         

            .skills-wrapper{
                display: flex;
                row-gap: 1rem;
                column-gap: 1rem;
                flex-flow: row;
                flex-wrap: wrap;
                max-width: 1280px;
                background: var(--color-background-mute);
                padding: 20px;
                border-radius: 5px;
                align-self: center;

                .skills-label{
                    background: #4c4c4d75;
                    padding: 5px 10px;
                    font-size: clamp(1rem,2vw,2rem);
                    border-radius: 5px;
                    cursor: pointer;
                    transition: .5s;

                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
    }

}